import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"

const Biskvitki = props => {
  return (
    <Layout>
      <SEO title={"Политика за използване на бисквитки"} />
      <div className="static-page-holder">
        <h1>ПОЛИТИКА ЗА ИЗПОЛЗВАНЕ НА БИСКВИТКИ</h1>
        <h2>Какво представляват бисквитките и за какво се използват?</h2>
        <p>
          Бисквитките са файлове с информация, включващи неограничено текстово
          съдържание, които се съхраняват в компютрите на потребителя с цел
          подобряване работата със съответния уебсайт. Тези файлове позволяват
          потребителят да бъде разпознат и уебсайтът да бъде адаптиран спрямо
          неговите предпочитания. Бисквитките обикновено съдържат името на
          уебсайта, към който принадлежат, времето, за което се съхраняват на
          компютъра на потребителя, и уникален номер.
        </p>
        <h2>Какви бисквитки използваме?</h2>
        <p>
          {" "}
          Използваме два вида бисквитки – на потребителска сесия и фиксирани.
          Последните са временни файлове, които остават в устройството на
          потребителя до края на потребителската му сесия или до затваряне на
          приложението (уеб браузъра). Фиксираните файлове остават на
          устройството на потребителя за периода, параметриран от бисквитките
          или докато бъдат изтрити ръчно от потребителя.
        </p>
        <h2>Как се използват бисквитките от този уебсайт?</h2>
        <div>
          Посещаването на този сайт може да създаде бисквитки със следните цели:
        </div>
        <ul>
          <li>Бисквитки за производителност на сайта;</li>
          <li>Бисквитки за анализиране на посетителите на сайта;</li>
          <li>Биквитки за географско насочване;</li>
          <li>Бисквитки за рекламна дейност;</li>
        </ul>
        <h2>Съдържат ли бисквитките лични данни? </h2>
        <p>
          Бисквитките не изискват информация с личен характер, за да могат да
          бъдат използвани и най-често не идентифицират личността на интернет
          потребителите. Личните данни, събрани при употребата на бисквитки,
          могат да бъдат събирани само за улесняване на употребата от
          потребителя. Тези данни са криптирани по начин, който не позволява на
          неоторизираните лица да имат достъп до тях.
        </p>
        <h2>Изтриване на бисквитки </h2>
        <p>
          По принцип, дадено приложение за достъп до уеб-страниците позволява
          съхраняването на бисквитки по подразбиране. Тези настройки могат да
          бъдат променени така, че автоматичното управление на бисквитките да
          бъде блокирано от уеб браузъра или потребителя да бъде информиран
          всеки път, когато се изпращат бисквитки до неговия терминал. Подробна
          информация за възможностите и начините на управление на бисквитки може
          да бъде намерена в настройките на браузъра. Ограничаването на
          използването на бисквитки може да повлияе на някои функции на
          уеб-страницата. Може да прочетете повече за употребата на бисквитки и
          защо тя е важна <a href="https://www.allaboutcookies.org/">ТУК</a>.
        </p>
      </div>
    </Layout>
  )
}

export default Biskvitki
